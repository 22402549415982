import React from 'react';
import { L1 } from '../types/ControlValues';

import './ControllerL1.scss';
import Slider, { ControlMode, Orientation } from './Slider';
import DroneImage from './Drone.svg';

export interface ControllerL1Props {
	l1?: L1;
	onChange?: (l1: L1) => void;
}

const ControllerL1 = React.forwardRef<HTMLDivElement, ControllerL1Props>(
	(props, ref) => {
		const { l1, onChange } = props;

		return (
			<div className="ControllerL1" ref={ref}>
				<table>
					<tr>
						<td>
							<Slider
								className="motor4"
								min={0}
								max={1}
								step={0.001}
								value={l1?.motor4}
								controlValue={l1?.motor4}
								orientation={Orientation.vertical}
								sliderWidth="160px"
								sliderHeight="40px"
								onChange={(value) => {
									if (onChange) onChange({ motor4: value });
								}}
							/>
						</td>
						<td colSpan={2} rowSpan={2}>
							<img src={DroneImage} width="250px" />
						</td>
						<td>
							<Slider
								className="motor1"
								min={0}
								max={1}
								step={0.001}
								value={l1?.motor1}
								controlValue={l1?.motor1}
								orientation={Orientation.vertical}
								sliderWidth="160px"
								sliderHeight="40px"
								onChange={(value) => {
									if (onChange) onChange({ motor1: value });
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<Slider
								className="motor3"
								min={0}
								max={1}
								step={0.001}
								value={l1?.motor3}
								controlValue={l1?.motor3}
								orientation={Orientation.vertical}
								sliderWidth="160px"
								sliderHeight="40px"
								onChange={(value) => {
									if (onChange) onChange({ motor3: value });
								}}
							/>
						</td>
						<td>
							<Slider
								className="motor2"
								min={0}
								max={1}
								step={0.001}
								value={l1?.motor2}
								controlValue={l1?.motor2}
								orientation={Orientation.vertical}
								sliderWidth="160px"
								sliderHeight="40px"
								onChange={(value) => {
									if (onChange) onChange({ motor2: value });
								}}
							/>
						</td>
					</tr>
				</table>
			</div>
		);
	}
);
export default ControllerL1;
