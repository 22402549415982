import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';

import './ListCard.scss';

export interface ListCardProps {
  drone: string;
}

export function ListCard(props: ListCardProps) {
  const { drone } = props;
  const path = '/drones/'+ drone;

  return (
    <Link to={path}>
      <Card sx={{ margin: 5, height: 200, backgroundColor: "blue"}}>
        <CardActionArea>
          <CardContent>
            {drone}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

export default ListCard;