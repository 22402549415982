import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
import Main from './routes/Main';
import DroneList from './routes/DroneList';
import DroneController from './routes/DroneController';

import './index.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			{/* <Route element={Header} /> */}
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="drones" element={<DroneList />} />
				<Route path="drones/:droneId" element={<DroneController />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker();
reportWebVitals();
