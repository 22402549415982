import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import socket from '../networks/socket-io';
import './DroneList.scss';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import ListCard from '../components/ListCard';

function DroneList() {
	const navigate = useNavigate();
	const [droneList, setDroneList] = useState([]);

	useEffect(() => {
		const onDroneList = (droneList: any) => {
			console.log('droneList', droneList);
			setDroneList(droneList);
		};
		socket.on('droneList', onDroneList);
		socket.emit('droneList');

		return () => {
			socket.off('droneList', onDroneList);
		};
	}, []);

	return (
		<div className="DroneList">
			<AppBar position="static">
				<Toolbar>
					DroneList{' '}
				</Toolbar>
			</AppBar>
			<div className="ListCell">
				<Box sx={{ width: "60%" }}>
					{droneList.map((drone, i) => {
						return (
							<ListCard drone={drone}></ListCard>
						);
					})}
				</Box>
			</div>
		</div>
	);
}

export default DroneList;
