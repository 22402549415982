import { io, Socket } from 'socket.io-client';
import { ControlValues } from '../types/ControlValues';
import { Sensors } from '../types/Sensors';
interface ServerToClientEvents {
	droneList: (droneList: []) => void;
	isOnline: (isOnline: boolean) => void;
	sensors: (sensors: Sensors) => void;
	controlValues: (controlValues: ControlValues) => void;
}

interface ClientToServerEvents {
	droneList: () => void;
	control: (controlValues: ControlValues) => void;
	targetDrone: (droneId: string | null) => void;
	pingToDrone: (callback: (dronePing: number) => void) => void;
}

let socket: Socket<ServerToClientEvents, ClientToServerEvents> | null = null;

function getSocket(): Socket<ServerToClientEvents, ClientToServerEvents> {
	if (socket) return socket;
	console.log('create socket.io instance', process.env.REACT_APP_SERVER_URL);
	socket = io(`${process.env.REACT_APP_SERVER_URL}/controller`, {
		forceNew: true,
		reconnectionAttempts: 3,
		timeout: 2000,
	});
	return socket;
}

export default getSocket();
