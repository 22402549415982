import React, { useEffect, useMemo, useRef } from 'react';
import { L3 } from '../types/ControlValues';
import { Sensors } from '../types/Sensors';

import './ControllerL3.scss';
import Slider, { Orientation } from './Slider';

export interface ControllerL3Props {
	l3?: L3;
	sensors?: Sensors;
	onChange?: (l3: L3) => void;
}

const ControllerL3 = React.forwardRef<HTMLDivElement, ControllerL3Props>(
	(props, ref) => {
		const { l3, sensors, onChange } = props;

		console.log(sensors?.angle);

		return (
			<div className="ControllerL3" ref={ref}>
				<table>
					<tr>
						<td>
							<Slider
								className="throttle"
								min={0}
								max={1}
								step={0.001}
								value={l3?.throttle}
								controlValue={l3?.throttle}
								orientation={Orientation.vertical}
								onChange={(value) => {
									if (onChange) onChange({ throttle: value });
								}}
							/>
						</td>
						<td></td>
					</tr>
					<tr>
						<td></td>
						<td>
							<Slider
								className="yaw"
								min={-90}
								max={90}
								step={0.001}
								value={sensors?.gyro.z ?? 0}
								controlValue={l3?.yaw}
								onChange={(value) => {
									if (onChange) onChange({ yaw: value });
								}}
							/>
						</td>
					</tr>
				</table>

				<table>
					<tr>
						<td></td>
						<td>
							<Slider
								className="roll"
								min={-90}
								max={90}
								step={0.001}
								value={sensors?.angle.x ?? 0}
								controlValue={l3?.roll}
								orientation={Orientation.vertical}
								onChange={(value) => {
									if (onChange) onChange({ roll: value });
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<Slider
								className="pitch"
								min={-90}
								max={90}
								step={0.001}
								value={sensors?.angle.y ?? 0}
								controlValue={l3?.pitch}
								onChange={(value) => {
									if (onChange) onChange({ pitch: value });
								}}
							/>
						</td>
						<td></td>
					</tr>
				</table>
			</div>
		);
	}
);
export default ControllerL3;
