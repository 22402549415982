import React, { useEffect, useMemo, useRef } from 'react';
import { L2 } from '../types/ControlValues';

import './ControllerL2.scss';
import Slider, { Orientation } from './Slider';

export interface ControllerL2Props {
	l2?: L2;
	onChange?: (l2: L2) => void;
}

const ControllerL2 = React.forwardRef<HTMLDivElement, ControllerL2Props>(
	(props, ref) => {
		const { l2, onChange } = props;

		return (
			<div className="ControllerL2" ref={ref}>
				<table>
					<tr>
						<td>
							<Slider
								className="throttle"
								min={0}
								max={1}
								step={0.001}
								value={l2?.throttle}
								controlValue={l2?.throttle}
								orientation={Orientation.vertical}
								onChange={(value) => {
									if (onChange) onChange({ throttle: value });
								}}
							/>
						</td>
						<td></td>
					</tr>
					<tr>
						<td></td>
						<td>
							<Slider
								className="balance_24_13"
								min={-1}
								max={1}
								step={0.001}
								value={l2?.balance_24_13}
								controlValue={l2?.balance_24_13}
								onChange={(value) => {
									if (onChange) onChange({ balance_24_13: value });
								}}
							/>
						</td>
					</tr>
				</table>

				<table>
					<tr>
						<td></td>
						<td>
							<Slider
								className="balance_23_14"
								min={-1}
								max={1}
								step={0.001}
								value={l2?.balance_23_14}
								controlValue={l2?.balance_23_14}
								orientation={Orientation.vertical}
								onChange={(value) => {
									if (onChange) onChange({ balance_23_14: value });
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<Slider
								className="balance_12_34"
								min={-1}
								max={1}
								step={0.001}
								value={l2?.balance_12_34}
								controlValue={l2?.balance_12_34}
								onChange={(value) => {
									if (onChange) onChange({ balance_12_34: value });
								}}
							/>
						</td>
						<td></td>
					</tr>
				</table>
			</div>
		);
	}
);
export default ControllerL2;
