import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './Main.scss';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function Main() {
	return (
		<div className="Main">
			<Container maxWidth="sm">
				<Box sx={{height:300}}>
					<p id="title">CouDrone</p>
					<p id="semi-title">:쉽고 빠른 드론 배송 서비스</p>
				</Box>
				<Button variant="outlined">
					<Link to="drones" style={{ color: 'black', textDecoration: 'none' }}>Manage Drones</Link>
				</Button>
			</Container>
			<Outlet />
		</div>
	);
}

export default Main;
