import React, { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import socket from '../networks/socket-io';
import './DroneController.scss';
import { Sensors } from '../types/Sensors';
import { ControlValues } from '../types/ControlValues';
import ControllerL1 from '../components/ControllerL1';
import ControllerL2 from '../components/ControllerL2';
import ControllerL3 from '../components/ControllerL3';
import { Tab, Tabs } from '@mui/material';
import { TabPanel } from '../components/TabPanel';

function DroneController() {
	const navigate = useNavigate();
	let { droneId } = useParams();

	const [isOnline, setIsOnline] = useState(false);
	const [pingInfo, setPingInfo] = useState({
		dronePing: -1,
		controllerPing: -1,
		totalPing: -1,
	});
	const [sensors, setSensors] = useState<Sensors>();
	const [controlValues, setControlValues] = useState<ControlValues>();
	// const [controlLayer, setControlLayer] = useState<number>(2);
	useEffect(() => {
		socket.emit('targetDrone', droneId || null);
		return () => {
			socket.emit('targetDrone', null);
		};
	}, [droneId]);
	const controlLayer = controlValues?.controlLayer ?? 1;
	// useEffect(() => {
	// 	socket.emit('control', { controlLayer: controlLayer });
	// }, [controlLayer]);
	useEffect(() => {
		const onIsOnline = (isOnline: boolean) => {
			console.log('isOnline', isOnline);
			setIsOnline(isOnline);
		};
		socket.on('isOnline', onIsOnline);

		const onSensors = (sensors: Sensors) => {
			setSensors(sensors);
		};
		socket.on('sensors', onSensors);

		const onControlValues = (controlValues: ControlValues) => {
			setControlValues(controlValues);
		};
		socket.on('controlValues', onControlValues);

		return () => {
			socket.off('isOnline', onIsOnline);
			socket.off('sensors', onSensors);
			socket.off('controlValues', onControlValues);
		};
	}, []);

	useInterval(() => {
		const start = performance.now();
		socket.emit('pingToDrone', (dronePing: number) => {
			const end = performance.now();
			const controllerPing = end - start;
			setPingInfo({
				dronePing: dronePing,
				controllerPing: controllerPing,
				totalPing: dronePing + controllerPing,
			});
		});
	}, 100);

	return (
		<div className="DroneController">
			<AppBar position="static" style={{}}>
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
					>
						<ArrowBackIcon
							onClick={() => {
								navigate('./..');
							}}
						/>
					</IconButton>
					<div style={{ flexGrow: 1 }}>
						<Typography variant="h6" component="div">
							Coudrone Controller
						</Typography>
						<Typography
							variant="subtitle2"
							component="p"
							className={clsx('status', isOnline ? 'online' : 'offline')}
						>
							{droneId}
						</Typography>
					</div>
					<div style={{ textAlign: 'end' }}>
						<p>
							<span>{sensors?.batteryPercent?.toFixed(2)}%</span>
							<span>({sensors?.batteryVoltage?.toFixed(2)}V)</span>
						</p>
						<p>
							<span>드론←</span>
							<span>{pingInfo.dronePing?.toFixed(2)}ms</span>
							<span>→서버←</span>
							<span>{pingInfo.controllerPing?.toFixed(2)}ms</span>
							<span>→컨트롤러</span>
						</p>
					</div>
				</Toolbar>
			</AppBar>
			<div className="center-items">
				<Tabs
					value={controlLayer}
					onChange={(_, index) => {
						socket.emit('control', { controlLayer: index });
					}}
					aria-label="disabled tabs example"
				>
					<Tab label="L1" value={1} />
					<Tab label="L2" value={2} />
					<Tab label="L3" value={3} />
					<Tab label="L4" value={4} />
				</Tabs>
			</div>
			<div className="center-items">
				<TabPanel value={controlLayer - 1} index={0}>
					<ControllerL1
						l1={controlValues?.l1}
						onChange={(l1) => {
							socket.emit('control', { l1: l1 });
						}}
					/>
				</TabPanel>
				<TabPanel value={controlLayer - 1} index={1}>
					<ControllerL2
						l2={controlValues?.l2}
						onChange={(l2) => {
							socket.emit('control', { l2: l2 });
						}}
					/>
				</TabPanel>
				<TabPanel value={controlLayer - 1} index={2}>
					<ControllerL3
						l3={controlValues?.l3}
						sensors={sensors}
						onChange={(l3) => {
							socket.emit('control', { l3: l3 });
						}}
					/>
				</TabPanel>
				<TabPanel value={controlLayer - 1} index={3}>
					Item Four
				</TabPanel>
			</div>
			{JSON.stringify(sensors)}
			<br />
			{JSON.stringify(controlValues)}
		</div>
	);
}

export default DroneController;
